import React from 'react'

const Packages = () => {
  return (
    <div>
  <section className="inner-page-banner-section gradient-bg">
    <div className="illustration-img"><img src="assets/images/inner-page-banner-illustrations/investment.png" alt="image-illustration" /></div>
    <div className="container">
      <div className="row">
        <div className="col-lg-6">
          <div className="inner-page-content-area">
            <h2 className="page-title">Investment Plans</h2>
            <nav aria-label="breadcrumb" className="page-header-breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="{{url('/')}}">Home</a></li>
                <li className="breadcrumb-item">Packages</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* inner-page-banner-section end */}
  <section className="calculate-profit-section pt-120 pb-120">
    <div className="bg_img" data-background="assets/images/invest-plan.jpg" />
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-8">
          <div className="section-header text-center text-white">
            <span className="section-subtitle">Calculate the amazing profits</span>
            <h2 className="section-title">You Can Earn</h2>
            <p>Find out what the returns on your current investments will be valued at, in future. All our issuers have obligation to pay dividends for first year regardless their financial situation that your investments are 100% secured. Calculate your profit from a share using our calculator:</p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="calculate-area wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.5s">
            <ul className="nav nav-tabs justify-content-around" id="calculatorTab" role="tablist">
              <li>
                <div className="icon"><img src="assets/images/icons/invest-calculate/1.svg" alt="icon-image" /></div>
                <h5 className="package-name">Basic Plan</h5>
                <span className="percentage">2%</span>
                <a className="active" id="basic-tab" data-toggle="tab" href="#basic" role="tab" aria-controls="basic" aria-selected="true">calculate</a>
              </li>
              <li>
                <div className="icon"><img src="assets/images/icons/invest-calculate/2.svg" alt="icon-image" /></div>
                <h5 className="package-name">satandard Plan</h5>
                <span className="percentage">3%</span>
                <a id="satandard-tab" data-toggle="tab" href="#satandard" role="tab" aria-controls="satandard" aria-selected="false">calculate</a>
              </li>
              <li>
                <div className="icon"><img src="assets/images/icons/invest-calculate/3.svg" alt="icon-image" /></div>
                <h5 className="package-name">premium Plan</h5>
                <span className="percentage">2%</span>
                <a id="premium-tab" data-toggle="tab" href="#premium" role="tab" aria-controls="premium" aria-selected="false">calculate</a>
              </li>
              <li>
                <div className="icon"><img src="assets/images/icons/invest-calculate/3.svg" alt="icon-image" /></div>
                <h5 className="package-name">VIP Plan</h5>
                <span className="percentage">2%</span>
                <a id="vip_plan-tab" data-toggle="tab" href="#vip_plan" role="tab" aria-controls="vip_plan" aria-selected="false">calculate</a>
              </li>
            </ul>
            <div className="tab-content" id="calculatorTabContent">
              <div className="tab-pane fade show active" id="basic" role="tabpanel" aria-labelledby="basic-tab">
                <div className="invest-amount-area text-center">
                  <h4 className="title">Minimum Amount</h4>
                  <div className="main-amount">
                    <input type="text" className="calculator-invest" id="basic-amount" readOnly />
                  </div>
                  <div id="slider-range-min-one" min={20} max={499} className="invest-range-slider" />
                </div>{/* invest-amount-area end */}
                <div className="plan-amount-calculate">
                  <div className="item">
                    <span className="caption">Basic plan</span>
                    <span className="details">Minimum Deposit $5</span>
                  </div>
                  <div className="item">
                    <span className="profit-amount">$12.67</span>
                    <span className="profit-details">Daily Profit</span>
                  </div>
                  <div className="item">
                    <span className="profit-amount">$12.67</span>
                    <span className="profit-details">per month</span>
                  </div>
                  <div className="item">
                    <a href="#" className="invest-btn btn-round">invest now</a>
                  </div>
                </div>{/* plan-amount-calculate end */}
              </div>
              <div className="tab-pane fade" id="satandard" role="tabpanel" aria-labelledby="satandard-tab">
                <div className="invest-amount-area text-center">
                  <h4 className="title">Minimum Amount</h4>
                  <div className="main-amount">
                    <input type="text" className="calculator-invest" id="satandard-amount" readOnly />
                  </div>
                  <div id="slider-range-min-two" className="invest-range-slider" />
                </div>{/* invest-amount-area end */}
                <div className="plan-amount-calculate">
                  <div className="item">
                    <span className="caption">satandard plan</span>
                    <span className="details">Minimum Deposit $15</span>
                  </div>
                  <div className="item">
                    <span className="profit-amount">$12.67</span>
                    <span className="profit-details">Daily Profit</span>
                  </div>
                  <div className="item">
                    <span className="profit-amount">$12.67</span>
                    <span className="profit-details">per month</span>
                  </div>
                  <div className="item">
                    <a href="#" className="invest-btn btn-round">invest now</a>
                  </div>
                </div>{/* plan-amount-calculate end */}
              </div>
              <div className="tab-pane fade" id="premium" role="tabpanel" aria-labelledby="premium-tab">
                <div className="invest-amount-area text-center">
                  <h4 className="title">Minimum Amount</h4>
                  <div className="main-amount">
                    <input type="text" className="calculator-invest" id="premium-amount" readOnly />
                  </div>
                  <div id="slider-range-min-three" className="invest-range-slider" />
                </div>{/* invest-amount-area end */}
                <div className="plan-amount-calculate">
                  <div className="item">
                    <span className="caption">premium plan</span>
                    <span className="details">Minimum Deposit $25</span>
                  </div>
                  <div className="item">
                    <span className="profit-amount">$12.67</span>
                    <span className="profit-details">Daily Profit</span>
                  </div>
                  <div className="item">
                    <span className="profit-amount">$12.67</span>
                    <span className="profit-details">per month</span>
                  </div>
                  <div className="item">
                    <a href="https://account.fx-globals.com" className="invest-btn btn-round">invest now</a>
                  </div>
                </div>{/* plan-amount-calculate end */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>


  )
}

export default Packages
