import React from 'react'
import GoldPlan from '../components/Home/GoldPlan'

const GoldMine = () => {
  return (
    <div>
      <div>
  <section className="inner-page-banner-section gradient-bg">
    <div className="illustration-img"><img src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1698567348/globe2.png" alt="image-illustration" /></div>
    <div className="container">
      <div className="row">
        <div className="col-lg-6">
          <div className="inner-page-content-area">
            <h2 className="page-title">Invest in Gold Mining</h2>
            <nav aria-label="breadcrumb" className="page-header-breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="{{url('/')}}">Home</a></li>
                <li className="breadcrumb-item">Sports</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section className="about-section start pt-120 pb-120">
    <div className="shape-el">
      <img src="assets/images/elements/about.png" alt="image" />
    </div>
    <div className="container">
      <div className="row">
        <div className="col-lg-6 mt-lg-0 mt-5">
          <div className="about-content">
            <div className="section-header">
              <span className="section-subtitle">We Are The leading dealer of physical precious metals </span>
              <h2 className="section-title">Gold Investment</h2>
              {/* <p>To meet <a href="https://account.fx-globals.com">today's challenges</a>, we've created a unique fund management system</p> */}
            </div>
            <p>With your retirement at stake, you want to be confident in the financial services companies that you work with—including whom you choose for purchasing physical precious metals.</p>
            <p>
            You need to put your savings into the hands of an experienced and well-respected dealer in gold, silver and other precious metals. Sleep better at night with a proven industry leader in your corner, someone who can show you how precious metal investments can protect your lifestyle and retirement—even when the economy gets shaky.


            </p>

            <p>
            FX GLOBALS is that trusted leader, proudly celebrated by our customers, the industry, and major media leaders alike.
            </p>
            <a href="https://account.fx-globals.com" className="btn btn-primary btn-hover text-small">get started</a>
          </div>
        </div>

        <div className="col-lg-6 mt-lg-0 mt-5">
          <div className="about-content">
            <div className="section-header">
              <h2 className="section-title">Core/Core-Plus</h2>
            </div>
            <p>We’ve become a leading dealer of physical precious metals. Today, our dynamic team of former wealth managers, financial advisors, and commodity brokers continue to help customers diversify their portfolios with gold, silver, platinum, and palladium.
            </p>
            <p>
            Our dedicated team of professionals has decades of combined experience, including past positions with Citigroup, Dun & Bradstreet, and IBM. This expertise, combined with our customer care process, will make your purchase or ROI setup feel smooth and simple.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
 <GoldPlan/>
</div>

    </div>
  )
}

export default GoldMine
