import React from 'react'
import SportsPlan from '../components/Home/SportsPlan'

const Sports = () => {
  return (
    <div>
      <div>
  <section className="inner-page-banner-section gradient-bg">
    <div className="illustration-img"><img src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1694430559/rugby-player.png" alt="image-illustration" /></div>
    <div className="container">
      <div className="row">
        <div className="col-lg-6">
          <div className="inner-page-content-area">
            <h2 className="page-title">Sports Investment</h2>
            <nav aria-label="breadcrumb" className="page-header-breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="{{url('/')}}">Home</a></li>
                <li className="breadcrumb-item">Sports</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section className="about-section start pt-120 pb-120">
    <div className="shape-el">
      <img src="assets/images/elements/about.png" alt="image" />
    </div>
    <div className="container">
      <div className="row">
        <div className="col-lg-6 mt-lg-0 mt-5">
          <div className="about-content">
            <div className="section-header">
              <span className="section-subtitle">We Offer Stable Profit & Safe Investment</span>
              <h2 className="section-title">Sports Investment</h2>
              {/* <p>To meet <a href="https://account.fx-globals.com">today's challenges</a>, we've created a unique fund management system</p> */}
            </div>
            <p>Plunge into the world of football battles and earn good maney at same time!there is a large number of players to choose from and their improvements with varying cost and profitability. the main advantage of our project is the opportunities that are given to each of its participants</p>
            <a href="https://account.fx-globals.com" className="btn btn-primary btn-hover text-small">get started</a>
          </div>
        </div>

        <div className="col-lg-6 mt-lg-0 mt-5">
          <div className="about-content">
            <div className="section-header">
              <h2 className="section-title">Core/Core-Plus</h2>
            </div>
            <p>At Fx Globals we allow investors all over the world to own shares of unique assets in sports. Buy and Sell Your Favorite Players On The World's First Sports Stock Market.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
 <SportsPlan/>
</div>

    </div>
  )
}

export default Sports
