import React from 'react'

const Offer = () => {
  return (
   <section style={{backgroundColor: '#07251c'}} className="offer-section pt-120 pb-120 bg_img">
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-lg-8">
        <div className="section-header text-center wow fadeIn" data-wow-duration="0.8s" data-wow-delay="0.5s">
          <span className="section-subtitle">Our mission is to help our User</span>
          <h2 className="section-title text-white">To Maximize Money</h2>
          <a href="https://dashboard.fx-globals.com" className="btn btn-primary btn-hover mt-30">what we offer</a>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-lg-12">
        <div className="offer-slider owl-carousel">
          <div className="offer-item" style={{backgroundColor: '#0d513f'}}>
            <div className="icon">
              <img src="assets/images/icons/offer/1.svg" alt="icon" />
            </div>
            <div className="content">
              <h3 className="title">smart deposit</h3>
              <p>Best way t o put your idle money to work.</p>
              <a href="https://dashboard.fx-globals.com" className="read-more-btn">read more<i className="icofont-long-arrow-right" /></a>
            </div>
          </div>{/* offer-item end */}
          <div className="offer-item">
            <div className="icon">
              <img src="assets/images/icons/offer/2.svg" alt="icon" />
            </div>
            <div className="content">
              <h3 className="title">One - Tap Invest</h3>
              <p>Invest without net baning/debit card.</p>
              <a href="https://dashboard.fx-globals.com" className="read-more-btn">read more<i className="icofont-long-arrow-right" /></a>
            </div>
          </div>{/* offer-item end */}
          <div className="offer-item">
            <div className="icon">
              <img src="assets/images/icons/offer/3.svg" alt="icon" />
            </div>
            <div className="content">
              <h3 className="title">invest &amp; saving</h3>
              <p>Grow your saving by investing as little as $5</p>
              <a href="https://dashboard.fx-globals.com" className="read-more-btn">read more<i className="icofont-long-arrow-right" /></a>
            </div>
          </div>{/* offer-item end */}
          <div className="offer-item">
            <div className="icon">
              <img src="assets/images/icons/offer/1.svg" alt="icon" />
            </div>
            <div className="content">
              <h3 className="title">smart deposit</h3>
              <p>Best way t o put your idle money to work.</p>
              <a href="https://dashboard.fx-globals.com" className="read-more-btn">read more<i className="icofont-long-arrow-right" /></a>
            </div>
          </div>{/* offer-item end */}
        </div>
      </div>
    </div>
  </div>
</section>

  )
}

export default Offer
